<template>
  <div class="web">
    <div class="content">
      <div class="box_title">
        <div class="left">
          <span class="font28 color33">关于我们</span>
          <span class="font14 color66">About us</span>
        </div>
      </div>
      <el-divider></el-divider>
      <div class="inner_text">
        <div v-html="content"></div>
      </div>
    </div>
  </div>
</template>
<script>
import {getAboutus} from "@/util/api";
export default {
  data() {
    return {
      content: "",
    };
  },
  mounted() {
    getAboutus({
      domainUrl: window.location.hostname,
    }).then((res) => {
      console.log(res);
      this.content = res.data.content;
    });
  },
};
</script>
<style lang="scss" scoped>
.content {
  width: 1200px;
  margin: 0 auto;
  background-color: #fff;
}
.box_title {
  padding: 30px 30px 15px;

  border-radius: 10px 10px 0 0;
}
.web {
  min-height: 100vh;
  width: 100%;
  padding: 40px 0;
  background-color: #f5f5f5;
}
.inner_text {
  width: 1140px;
  margin: 0 auto;
  background-color: #fff;
  min-height: 600px;
  padding: 30px 0 100px 0;
  border-radius: 0 0 10px 10px;
  p {
    font-size: 14px;
    line-height: 30px;
  }
  img {
    width: 100%;
  }
}
</style>
